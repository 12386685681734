import moment from "moment";
import { FC, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { PageTitle } from '../../../../_metronic/layout/core'
import { SelectOptionModel } from '../../../components/Helpers'
import { DATE_FORMAT } from "../../../helpers/constants";
import { useAuth } from "../../../modules/auth";
import { codeListModel } from '../../../modules/reports/settlement-report/Models'
import { codeList } from '../../../modules/reports/settlement-report/Requests'
import CurrencyGgrChart from "./CurrencyGgrChart";
import MonthlyGgrChart from "./MonthlyGgrChart";
import "../dashboard-styles.scss"

const monthlyGgrStart = moment().subtract(6, 'month').startOf('month')
const monthlyGgrEnd = moment().subtract(1, 'month').startOf('month')
const currencyGgrStart = moment().subtract(1, 'month').startOf('month')
const currencyGgrEnd = currencyGgrStart
const monthFormat = 'MMMM YYYY'

const DashboardPage: FC = () => {
  const auth = useAuth()

  const [first, setFirst] = useState(true);

  useEffect(() => {
    if (first) void getCodeList()
  }, [first])

  const getCodeList = async () => {
    setFirst(false)

    const response = await codeList({ types: ['game_provider', 'currency', 'game_category', 'sites'] })
    const data = response.data.data

    //Log response
    console.log(data)

    //create game provider options
    const game_provider_options: SelectOptionModel[] = data.game_provider.map((gameProvider: codeListModel) => {
      return { label: gameProvider.code + ' - ' + gameProvider.name, value: gameProvider.code }
    })

    //create currency options
    const currency_options: SelectOptionModel[] = data.currency.map((currency: codeListModel) => {
      return { label: currency.code, value: currency.code }
    })

    //create game category options
    const game_category_options: SelectOptionModel[] = data.game_category.map((gameCategory: codeListModel) => {
      return { label: gameCategory.code, value: gameCategory.code }
    })

    const site_code_options: SelectOptionModel[] = data.sites.map((siteCode: string) => {
      return { label: siteCode, value: siteCode }
    })

    //store options in local storage
    localStorage.setItem('game_provider_options', JSON.stringify(game_provider_options))
    localStorage.setItem('currency_options', JSON.stringify(currency_options))
    localStorage.setItem('game_category_options', JSON.stringify(game_category_options))
    localStorage.setItem('site_options', JSON.stringify(site_code_options))
  }

  return (
    <>
      {auth.hasPermission('chart-view') && // this permission does not exist, but super admin ignores all permissions
        <div className="container" style={{ maxWidth: 'none' }}>
          <div className="row">
            <div className="col-md-12 col-lg-8">
              <div className="card rounded-5">
                <div className="card-body">
                  <div className="card-title text-center fw-bold h4">
                    Monthly GGR ({monthlyGgrStart.format(monthFormat)} - {monthlyGgrEnd.format(monthFormat)})
                  </div>
                  <MonthlyGgrChart
                    startDate={monthlyGgrStart.format(DATE_FORMAT)}
                    endDate={monthlyGgrEnd.format(DATE_FORMAT)}
                  />
                </div>
              </div>
            </div>

            <div className="col-md-12 col-lg-4">
              <div className="card rounded-5">
                <div className="card-body">
                  <div className="card-title text-center fw-bold h4">
                    GGR by Currency ({currencyGgrStart.format(monthFormat)})
                  </div>
                  <CurrencyGgrChart
                    startDate={currencyGgrStart.format(DATE_FORMAT)}
                    endDate={currencyGgrEnd.format(DATE_FORMAT)}
                  />
                  <div className='text-end fs-7'>* Each currency is converted to USD.</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </>
  )
}

const DashboardWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle>{intl.formatMessage({ id: 'MENU.DASHBOARD' })}</PageTitle>
      <DashboardPage />
    </>
  )
}

export { DashboardWrapper }
