import moment from 'moment';
import { useEffect, useState } from 'react';
import { MONTH_FORMAT } from "../../../../helpers/constants";
import { TxnCountReportResponse, TxnCountReportFilter, TxnCountReportResponseData, TxnCountReportIndexResponse } from '../Models';
import * as Yup from "yup";
import MessageModal from '../../../../components/MessageModal';
import { ReportTable } from '../../../../components/ReportTable';
import {
  checkRequestResponse,
  getSelectOptions,
  removeEmptyFilters,
  SelectOptionModel
} from '../../../../components/Helpers';
import TableFilter from "../../../../components/table-filter/components/TableFilter";
import { ApiResponse, FinalRequest, RequestPagination, ResponsePagination } from "../../../../helpers/types/api-types";
import { txnCountReport, txnCountReportURL } from '../Requests';
import { TableFilterInput } from "../../../../components/table-filter/table-filter-types";
import TablePagination, { initialPagination } from '../../../../components/TablePagination/TablePagination';
import FileExport from "../../../../helpers/utils/FileExport";

const initialFilters: TxnCountReportFilter = {
  month: moment().subtract(1, 'M').startOf('M').format('YYYY-MM'),
  group_by: 'site'
}

const TxnCountReport = () => {
  const [filter, setFilter] = useState<TxnCountReportFilter>(initialFilters)
  const [data, setData] = useState<TxnCountReportResponse[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [columns, setColumns] = useState<string[]>([])
  const [pagination, setPagination] = useState<ResponsePagination>(initialPagination)
  const [siteOptions, setSiteOptions] = useState<SelectOptionModel[]>([])
  const [currencyOptions, setCurrencyOptions] = useState<SelectOptionModel[]>([])

  useEffect(() => {
    setSiteOptions(getSelectOptions('site_options', false))
    setCurrencyOptions(getSelectOptions('currency_options'))
  }, [])

  const onFilterHandler = async (filters: TxnCountReportFilter) => {
    setIsLoading(true)

    try {
      const response = await txnCountReport(removeEmptyFilters(filters))

      if (!checkRequestResponse(response)) {
        setIsLoading(false)
        return false
      }

      setData(response.data.data.rows)
      setPagination(response.data.data.paginations ?? initialPagination)
      setFilter(filters)
    } catch (e) {
      MessageModal({ type: 'failed' })
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (data.length > 0) {
      let tempCol: string[] = []
      Object.keys(data[0]).map((value) => {
        tempCol.push(value)
      })

      tempCol.length > 0 && setColumns(tempCol)
    }
  }, [data])

  const tableFilterFields: TableFilterInput[]= [
    {
      label: 'Month',
      name: 'month',
      type: 'month',
      defaultValue: moment().subtract(1, 'month').startOf('month').format(MONTH_FORMAT),
    },
    {
      label: 'Group By',
      name: 'group_by',
      type: 'select',
      defaultValue: 'site',
      options: [
        { label: 'Site', value: 'site' },
        { label: 'Site & Game Provider', value: 'site_gp' },
      ]
    },
    {
      label: 'Sites',
      name: 'sites',
      type: 'multi-select',
      options: siteOptions,
      placeholder: 'Select sites'
    },
    {
      label: 'Currency Code',
      name: 'currency_code',
      type: 'select',
      options: currencyOptions,
      defaultValue: '',
    },
  ]

  const onResponseHandler = (response: ApiResponse<TxnCountReportResponseData>, params: FinalRequest<TxnCountReportFilter>) => {
    const actualResponse = response as TxnCountReportIndexResponse

    let report: TxnCountReportResponse[]
    let pagination: ResponsePagination

    report = actualResponse.data.rows ?? []

    if ('paginations' in actualResponse.data && actualResponse.data.paginations !== undefined) {
      pagination = actualResponse.data.paginations
    } else {
      pagination = initialPagination
    }

    const txnReport = report
    setData(txnReport)
    setPagination(pagination)
    setFilter(params)

    setIsLoading(false)
  }

  const exportReport = () => {
    const paramsWithoutPagination: TxnCountReportFilter = filter

    delete paramsWithoutPagination.paginate
    delete paramsWithoutPagination.page
    delete paramsWithoutPagination.per_page

    txnCountReport(paramsWithoutPagination)
      .then(response => {
        if (!checkRequestResponse(response)) return false

        const exportData: TxnCountReportResponse[] = response.data.data

        const month: string = paramsWithoutPagination.month

        const fileName: string = `${month}_TXN_COUNT_REPORT.csv`
        const file = new FileExport<TxnCountReportResponse>(fileName, exportData)
        console.log(file)
        file.export()
      })
  }

  return (
    <>
      <TableFilter<TxnCountReportFilter, TxnCountReportResponseData>
        fields={tableFilterFields}
        validationSchema={Yup.object().shape({
          month: Yup.date().required('Month is required'),
          sites: Yup.array(),
          group_by: Yup.string().required('Group by is required'),
          currency_code: Yup.string()
        })}
        apiUrl={txnCountReportURL}
        onSubmit={() => setIsLoading(true)}
        submitOnInit = {true}
        onResponse={onResponseHandler}
        buttons={[
          {
            label: 'Export',
            icon: {
              sourceType: 'path',
              source: '/media/icons/duotune/files/fil017.svg',
            },
            onClick: exportReport,
            disabled: data.length <= 0,
          },
        ]}
        pagination={{
          paginate: 1,
          per_page: pagination.per_page,
          page: pagination.current_page
        }}
      />
      <ReportTable
        data={data}
        columns={columns}
        isLoading={isLoading}
      />
      <TablePagination
        pagination={pagination}
        onPaginate={(paginationData: RequestPagination) => {
          onFilterHandler({
            ...filter,
            ...paginationData,
          })
          setFilter({
            ...filter,
            ...paginationData,
          })
        }}
      />
    </>
  )
}
export default TxnCountReport