import { Fragment, isValidElement } from "react";
import { formatValue, freezeColumn, isNumber, roundOff } from "./Helpers";

interface params {
  columns: string[];
  data: any[];
  isLoading: boolean
  game_provider?: string
  appendixRows?: JSX.Element[]
  merchant?: boolean
  alignLeftCol?: any[]
  alignMidCol?: any[]
  title?: string
  freezeHeader?: boolean
  freezeCol?: number
  upCaseCol?: any[]
}

let ReportTable = ({data, columns, isLoading, game_provider = '', merchant = false, appendixRows = [], alignLeftCol = [], alignMidCol = [], title = '', freezeHeader = false, freezeCol = 0, upCaseCol = []}: params) => {
  const parseValue = (value: any) => {
    if (value === '' || value === null) return '-';

    if (isNumber(value) && ('' + value).includes('.')) {
      let dp = countDp(value)
      return Number(value).toFixed(dp)
    }

    return '' + value
  }

  const countDp = (value: any) => {
    return value.toString().split(".")[1].length
  }

  const textAlignment = (column: string) => {
    let textAlign = 'text-end'
    if (alignLeftCol.includes(column)) {
      textAlign = 'text-start'
    } else if (alignMidCol.includes(column)) {
      textAlign = 'text-center'
    }
    return textAlign
  }

  const textCapitalize = (column: string) => {
    let textCapital = 'text-capitalize'

    if (upCaseCol.includes(column)) {
      textCapital = 'text-uppercase'
    }

    return textCapital
  }

  let freezeHeaderClass: string = freezeHeader ? 'freeze-header' : ''

  return (
    <>
      <div className={`card p-5`}>
        <div className={`card table-responsive ${freezeHeaderClass}`}>
          <link href='https://fonts.googleapis.com/css?family=Roboto' rel='stylesheet' />
          <table className="card-rounded table table-hover table-row-bordered table-row-gray-300 gs-7">
            <thead>
            {title && <tr className="fw-bolder fs-5 text-gray-800">
              <th className="align-top text-center text-capitalize pt-5" colSpan={columns.length}><u>{title}</u></th>
            </tr>}
            {columns.length > 0 &&
              <tr className={`fw-bolder fs-6 text-gray-800 ${freezeColumn(freezeCol)}`}>
                {columns.map((column) => {
                  let textAlign = textAlignment(column)
                  let textCapital = textCapitalize(column)
                  return <th key={column} className={`align-top ${textAlign} ${textCapital} pt-5`}>
                    {column.split('_').join(' ')}
                  </th>
                })}
              </tr>
            }
            </thead>
            <tbody style={{ fontFamily: 'Roboto' }}>
            {
              !isLoading && data.length > 0 &&
              data.map((row, index) => {
                return <tr key={index} className={`${freezeColumn(freezeCol)}`}>{
                  columns.map((column, index) => {
                    let value = parseValue(row[column])
                    let winLoseStyling = ''

                    if (column.toLocaleLowerCase().includes('win') && column.toLocaleLowerCase().includes('lose') && isNumber(value) && !merchant) {
                      winLoseStyling = Number(value) >= 0 ? 'text-success fw-bold' : 'text-danger fw-bolder'
                    } else if (column.toLocaleLowerCase().includes('company') && column.toLocaleLowerCase().includes('win') && column.toLocaleLowerCase().includes('lose') && isNumber(value) && merchant) {
                      winLoseStyling = Number(value) >= 0 ? 'text-success fw-bold' : 'text-danger fw-bolder'
                    }

                    let textAlign = textAlignment(column)
                    return (
                      <td
                        key={`${index}_${row[column]}_${game_provider}`}
                        className={`${textAlign} ${winLoseStyling}`}
                      >
                        {
                          isNumber(value) && ('' + value).includes('.')
                            ? formatValue(Number(value), countDp(value))
                            : isValidElement(row[column]) ? row[column] : value // check is react element
                        }
                      </td>
                    )
                  })}</tr>
              })
            }
            {!isLoading && appendixRows.length > 0 && appendixRows.map((appendixRow: JSX.Element, index: number) => (
              <Fragment key={index}>{appendixRow}</Fragment>
            ))}
            {
              data.length < 1 &&
              <tr>
                <td className={'text-center text-muted pt-6'} colSpan={columns.length}>
                  {isLoading ? 'Loading...' : 'No records found'}
                </td>
              </tr>
            }
            </tbody>
          </table>
        </div>
      </div>
    </>
  )
}

export { ReportTable };

